<template>
  <section class="m-4">
      <b-container>
      <b-row>
          <h1>Om</h1>
          <hr>
      </b-row>
      <b-row>
          <p>Denne siden er laget av Risa IT Consulting. </p>
      </b-row>
      <b-row>
          <h3>Referanser</h3>
          <hr>
      </b-row>
      <b-row>
            <ul>
              <li>Meteorologisk Institutt: <a href="https://api.met.no" target="_blank">WeatherAPI</a></li>
              <li><a href="https://snl.no/Norges_flagg" target="_blank">Norges flagg (SNL)</a></li>
              <li><a href="https://simplemaps.com/data/no-cities" target="_blank">Simple maps </a></li>
          </ul>
      </b-row>
      </b-container>
  </section>
</template>

<script>
export default {
    name: "About"
}
</script>

<style>

</style>